<template>
  <div
    class="col-6"
    :style="
      isEmpty(customer) === true
        ? 'width: 80% !important'
        : 'width: 60% !important'
    "
  >
    <div
      v-if="isEmpty(customer) === true"
      class="chat-sector flex justify-center items-center"
    >
      <div class="not-chat font-semibold q-pa-xl">
        กดรับแชทและกดช่องแชท เพื่อเข้าสู่บทสนทนา
      </div>
    </div>
    <div v-else class="chat-sector relative-position">
      <div ref="chatheader" class="chat-header font-semibold">
        <div class="flex items-center">
          <q-avatar size="md">
            <img :src="customer.picture_url" />
          </q-avatar>
          <span class="text-normal">&nbsp;{{ customer.display_name }}</span>
        </div>
        <div
          v-if="
            isPreview === true ||
            searchObjectById(chattingMessages, customer.id) === undefined
          "
          class="q-gutter-x-sm"
        >
          <q-btn
            @click="handleClickStartConversation(customer.id, customer.web.id)"
            unelevated
            color="white"
            text-color="normal-green"
            icon-right="add_circle"
            label="เริ่มการสนทนา"
            size="sm"
            class="btn-radius"
          />
        </div>
        <div v-else class="q-gutter-x-sm">
          <q-btn
            @click="
              handleClickIsPin(
                customer.id,
                searchObjectById(
                  viewMode === 'overall' ? searchMessages : chattingMessages,
                  customer.id
                )?.is_pin === false
                  ? 'is_pin=1'
                  : 'is_pin=0'
              )
            "
            unelevated
            :color="
              searchObjectById(
                viewMode === 'overall' ? searchMessages : chattingMessages,
                customer.id
              )?.is_pin === false
                ? 'dark-orange'
                : 'black-orange'
            "
            text-color="white"
            icon-right="push_pin"
            :label="
              searchObjectById(
                viewMode === 'overall' ? searchMessages : chattingMessages,
                customer.id
              )?.is_pin === false
                ? 'ปักหมุด'
                : 'เลิกปักหมุด'
            "
            size="sm"
            class="btn-radius"
          />
          <q-btn
            @click="handleClickUnread(customer.id)"
            unelevated
            color="yellow"
            text-color="white"
            icon-right="email"
            label="Unread"
            size="sm"
            class="btn-radius"
          />
          <q-btn
            @click="
              handleClickIsProcess(
                customer.id,
                searchObjectById(
                  viewMode === 'overall' ? searchMessages : chattingMessages,
                  customer.id
                )?.is_process === false
                  ? 'is_process=1'
                  : 'is_process=0'
              )
            "
            unelevated
            :color="
              searchObjectById(
                viewMode === 'overall' ? searchMessages : chattingMessages,
                customer.id
              )?.is_process === false
                ? 'dark-blue'
                : 'black-blue'
            "
            text-color="white"
            icon-right="chat"
            :label="
              searchObjectById(
                viewMode === 'overall' ? searchMessages : chattingMessages,
                customer.id
              )?.is_process === false
                ? 'รอติดตามผล'
                : 'เลิกติดตาม'
            "
            size="sm"
            class="btn-radius"
          />
          <q-btn
            @click="confirmIsEnd = true"
            unelevated
            color="dark-green"
            text-color="white"
            icon-right="mark_chat_read"
            label="จบการสนทนา"
            size="sm"
            class="btn-radius"
          />
        </div>
        <q-dialog v-model="confirmIsEnd" persistent>
          <q-card class="rounded" style="width: 400px; max-width: 30vw">
            <q-card-section>
              <div class="font-medium text-h6 text-center">
                ยืนยันจบการสนทนา?
              </div>
            </q-card-section>

            <q-card-section class="q-pt-none"> </q-card-section>

            <q-card-section class="q-pt-none">
              <div class="flex q-col-gutter-x-sm justify-end font-medium">
                <div>
                  <q-btn
                    v-close-popup
                    unelevated
                    color="grey-95"
                    text-color="white"
                    label="ยกเลิก"
                    size="md"
                    class="btn-radius full-width"
                  />
                </div>
                <div>
                  <q-btn
                    @click="handleClickEndChat(customer.id)"
                    v-close-popup
                    unelevated
                    color="gd-green"
                    text-color="white"
                    label="ยืนยัน"
                    size="md"
                    class="btn-radius full-width"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>

        <q-dialog v-model="confirmForceEnd" persistent>
          <q-card class="rounded" style="width: 400px; max-width: 30vw">
            <q-card-section>
              <div class="font-medium text-h6 text-center">
                ไม่สามารถเริ่มการสนทนาได้
              </div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <div class="font-regular text-center text-greys">
                เนื่องจากการสนทนานี้เป็นของ Staff : {{ chatStaff }}
              </div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <div class="flex q-col-gutter-x-sm justify-end font-medium">
                <div>
                  <q-btn
                    v-close-popup
                    unelevated
                    color="grey-95"
                    text-color="white"
                    label="ยกเลิก"
                    size="md"
                    class="btn-radius full-width"
                  />
                </div>
                <div>
                  <q-btn
                    @click="handleClickForceEnd(customer.id)"
                    v-close-popup
                    unelevated
                    color="gd-green"
                    text-color="white"
                    label="ยืนยันรับแชทแทน"
                    size="md"
                    class="btn-radius full-width"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
      <q-scroll-area
        ref="scrollarea"
        id="scroll-sector"
        :style="{ height: setChatHeight() }"
        @scroll="onScroll(loadingChat)"
      >
        <div class="chat-body q-gutter-y-sm q-mx-md q-mt-none font-regular">
          <div v-if="isLoadScroll === true" class="loading-text font-regular">
            กำลังโหลดข้อความ
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
          <div
            v-for="(chatBox, index) in chatBoxs"
            :key="index"
            class="relative-position"
          >
            <!-- วันที่ -->
            <div
              v-if="chatBox.chatDate !== null"
              class="chat-item center q-mb-sm"
            >
              <div class="date-item q-px-md">
                {{ chatBox.chatDate }}
              </div>
            </div>
            <!-- ข้อความตรงกลาง -->
            <div
              v-if="chatBox.message.type === 'html'"
              class="chat-item center"
            >
              <div
                class="center-message"
                :class="
                  chatBox.message.data.indexOf('red') !== -1 ? 'red' : 'grey'
                "
                style="max-width: 75%"
              >
                <div class="text-time text-center">
                  {{ chatTimeFormat(chatBox.created_at) }}
                </div>
                <div v-html="chatBox.message.data"></div>
              </div>
            </div>
            <!-- ข้อความแอดมิน -->
            <div
              v-else-if="
                chatBox.staff_id !== null ||
                chatBox.is_bot === true ||
                chatBox.message.type === 'flex'
              "
            >
              <div class="chat-item q-gutter-x-sm mine">
                <div
                  v-if="
                    !chatBox.message.resend || chatBox.message.resend === false
                  "
                  class="text-time self-end"
                >
                  {{ chatTimeFormat(chatBox.created_at) }}
                </div>
                <div
                  v-if="chatBox.message.type === 'flex'"
                  class="message"
                  :class="chatBox.is_bot === true ? 'alt' : ''"
                >
                  {{ chatBox.message.altText }}
                </div>
                <div
                  v-else-if="chatBox.message.type === 'sticker'"
                  style="width: 15%"
                >
                  <img
                    :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${chatBox.message.stickerId}/android/sticker.png`"
                    @load="chatScrollBottom"
                    alt=""
                    class="full-width"
                  />
                </div>
                <img
                  v-else-if="chatBox.message.type === 'image'"
                  :data-original="chatBox.message.originalContentUrl"
                  :src="chatBox.message.previewImageUrl"
                  @click="handleShowImage(index)"
                  @dragstart="onDragStart"
                  @load="chatScrollBottom"
                  :id="'image' + index"
                  class="img"
                  alt="dragable"
                  style="width: 15%"
                />
                <div
                  v-else-if="chatBox.message.type === 'text'"
                  class="message"
                  :class="chatBox.is_bot === true ? 'alt' : ''"
                  v-html="chatBox.message.text"
                ></div>
                <div v-else class="message">Unsupport Message</div>
                <div class="avatar self-start">
                  <q-avatar
                    size="lg"
                    :color="chatBox.is_bot === true ? 'light-blue' : 'gd-green'"
                    text-color="white"
                  >
                    {{
                      chatBox.is_bot === true
                        ? "B"
                        : chatBox.staff_name !== undefined
                        ? chatBox.staff_name.charAt(0).toUpperCase()
                        : "U"
                    }}
                  </q-avatar>
                  <div class="admin-info">
                    {{
                      chatBox.is_bot === true
                        ? "Bot"
                        : chatBox.staff_name !== undefined
                        ? chatBox.staff_name
                        : "Unknown"
                    }}
                  </div>
                </div>
              </div>
              <div
                @click="handleClickResend(customer.id, chatBox.id)"
                v-if="chatBox.message.resend === true"
                class="fail-message cursor-pointer"
              >
                ส่งข้อความไม่สำเร็จ
                <q-icon name="refresh"></q-icon>
              </div>
            </div>
            <!-- ข้อความ user -->
            <div
              v-else-if="chatBox.staff_id === null"
              class="chat-item q-gutter-x-sm"
            >
              <div class="self-start">
                <q-avatar size="lg">
                  <img :src="customer.picture_url" />
                </q-avatar>
              </div>

              <div
                v-if="chatBox.message.type === 'image'"
                :style="chatBox.message.originalContentUrl ? 'width: 15%' : ''"
              >
                <img
                  v-if="chatBox.message.originalContentUrl"
                  @click="handleShowImage(index)"
                  @dragstart="onDragStart"
                  @load="chatScrollBottom"
                  :id="'image' + index"
                  :data-original="chatBox.message.originalContentUrl"
                  :src="chatBox.message.previewImageUrl"
                  class="img"
                  alt="dragable"
                  style="width: 100%"
                />
                <q-spinner v-else color="grey" size="3em" :thickness="2" />
              </div>

              <div
                v-else-if="chatBox.message.type === 'sticker'"
                style="width: 15%"
              >
                <img
                  :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${chatBox.message.stickerId}/android/sticker.png`"
                  @load="chatScrollBottom"
                  alt=""
                  class="full-width"
                />
              </div>
              <div v-else class="message">
                {{
                  chatBox.message.text
                    ? chatBox.message.text
                    : "Unsupport Message"
                }}
              </div>
              <div class="text-time self-end">
                {{ chatTimeFormat(chatBox.created_at) }}
              </div>
            </div>
          </div>
        </div>
      </q-scroll-area>
      <div
        v-if="searchObjectById(chattingMessages, customer.id) !== undefined"
        ref="chatinput"
        class="chat-input"
      >
        <q-file
          @update:model-value="(val) => handleUpdateFile(val)"
          v-model="file"
          label="Standard"
          ref="fileInput"
          style="display: none"
        />
        <div class="input-text q-px-md q-pt-md">
          <div id="uploadImgBtn" class="custom-dz">
            <q-btn
              v-show="dropzone !== null && dropzone.files.length > 0"
              @click="handleClickRemoveFile"
              round
              dense
              size="md"
              color="grey"
              text-color="white"
              icon="close"
              class="remove-dz"
            />
          </div>
          <q-input
            @keydown.enter="handleSendChat($event, customer.id)"
            @paste="handlePaste"
            @drop="onDrop"
            @dragover="allowDrop"
            borderless
            rounded
            bottom-slots
            type="textarea"
            :rows="1"
            v-model="messageText"
            dense
            class="font-regular drop-area"
          >
            <template v-slot:counter>
              <div class="relative-position flex">
                <div
                  v-if="showEmojiPicker"
                  ref="emojiPicker"
                  class="emoji-sector"
                >
                  <Picker
                    :data="emojiIndex"
                    title="Pick your emoji…"
                    emoji="point_up"
                    @select="onSelectEmoji"
                  />
                </div>
                <div
                  ref="stickerPicker"
                  v-if="showStickerPicker"
                  class="sticker-sector"
                >
                  <div class="sticker-header">
                    <div
                      @click="onSelectStickerPack(item.package_id)"
                      v-for="(item, index) in sticker"
                      :key="index"
                      class="q-pa-sm"
                    >
                      <img
                        :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${item.first_id}/android/sticker.png`"
                        alt=""
                        :class="
                          item.package_id === currentStickerPackage
                            ? 'activate'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <div
                    v-show="item.package_id === currentStickerPackage"
                    v-for="(item, index) in sticker"
                    :key="index"
                    class="sticker-body flex"
                  >
                    <div
                      @click="
                        onSelectSticker(
                          customer.id,
                          item.package_id,
                          item.first_id - 1 + n
                        )
                      "
                      v-for="n in item.total"
                      :key="n"
                    >
                      <img
                        :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${
                          item.first_id - 1 + n
                        }/android/sticker.png`"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div ref="stickerIcon" class="q-mr-md">
                  <q-btn
                    @click="showStickerPicker = !showStickerPicker"
                    round
                    dense
                    flat
                    icon="sentiment_satisfied_alt"
                  />
                </div>
                <div ref="emojiIcon" class="q-mr-md">
                  <q-btn
                    @click="showEmojiPicker = !showEmojiPicker"
                    round
                    dense
                    flat
                    icon="emoji_emotions"
                  />
                </div>

                <q-btn
                  @click="handleChooseFile"
                  round
                  dense
                  flat
                  icon="image"
                  class="q-mr-md"
                />
                <q-btn
                  @click="handleSendChat($event, customer.id)"
                  round
                  dense
                  flat
                  icon="send"
                />
              </div>
            </template>
            <template v-slot:hint>
              <div style="font-size: 1rem">
                กด Shift + Enter เพื่อเริ่มต้นบรรทัดใหม่
              </div>
            </template>
          </q-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from "vue";
import { setRoomWork } from "@/services/leftsector-service";
import {
  sendText,
  sendSticker,
  resend,
  getStaff,
} from "@/services/centersector-service";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import data from "emoji-mart-vue-fast/data/all.json";

import stickerData from "../sticker.config.json";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://office.chatbot.softbot.space";
export default {
  components: {
    Picker,
  },
  props: [
    "chatBoxs",
    "customer",
    "isPreview",
    "isLastMessage",
    "chattingMessages",
    "searchMessages",
    "staff",
    "staffLists",
    "bankMessage",
    "chatStaff",
    "viewMode",
    "loadingChat",
  ],
  setup(props) {
    const { updateRooms, isEmpty, searchObjectById } = utilily();
    const store = useStore();

    const chatheader = ref(null);
    const chatinput = ref(null);
    const fileInput = ref(null);
    const scrollarea = ref(null);
    const emojiPicker = ref(null);
    const emojiIcon = ref(null);
    const stickerPicker = ref(null);
    const stickerIcon = ref(null);

    let emojiIndex = new EmojiIndex(data);

    const state = reactive({
      messageText: "",
      confirmIsEnd: false,
      viewer: [],
      confirmForceEnd: false,
      customer: null,
      chatId: null,
      chatStaff: "",
      isLoadScroll: false,
      currentDate: null,
      dropzone: null,
      file: null,
      isLastMessage: false,
      chatLength: 0,
      showEmojiPicker: false,
      showStickerPicker: false,
      sticker: stickerData,
      staff: null,
      viewMode: "all",
      currentStickerPackage: stickerData[0].package_id,
      base_url: process.env.VUE_APP_API_URL
        ? process.env.VUE_APP_API_URL
        : "https://office.chatbot.softbot.space",
    });

    watch(
      () => [
        props.bankMessage,
        props.chatBoxs,
        props.customer,
        props.isLastMessage,
      ],
      (
        [bankMessage, chatBoxs, customer, isLastMessage, viewMode],
        [
          prevBankMessage,
          prevChatbox,
          prevCustomer,
          prevIsLastMessage,
          prevViewMode,
        ]
      ) => {
        console.log(prevChatbox, customer.id, prevCustomer.id);

        state.staff = props.staff;
        state.chatStaff = props.chatStaff;

        if (viewMode !== prevViewMode) {
          state.viewMode = viewMode;
        }

        if (isEmpty(customer) === true || customer.id !== prevCustomer.id) {
          initDropzone(customer.id);
          document.addEventListener("click", handleClickOutSide);
        }

        if (isLastMessage !== prevIsLastMessage) {
          state.isLastMessage = props.isLastMessage;
        }

        if (chatBoxs.length > 0) {
          checkStaff(chatBoxs, props.staffLists);
          state.chatLength = chatBoxs.length;
          state.customer = customer;
          state.chatId = chatBoxs[0].id;
          chatBoxs.forEach((element, index) => {
            if (
              element.message.type === "image" ||
              element.message.type === "staff_image"
            ) {
              const intv = setInterval(() => {
                if (document.getElementById("image" + index) !== null) {
                  clearInterval(intv);
                  const image = document.getElementById("image" + index);
                  state.viewer[index] = new Viewer(image, {
                    inline: false,
                    navbar: false,
                    title: false,
                    toolbar: {
                      rotateLeft: true,
                      rotateRight: true,
                    },
                    url(image) {
                      return image.getAttribute("data-original");
                    },
                  });
                }
              }, 100);
            }
          });
        }
        if (bankMessage !== prevBankMessage) {
          state.messageText = bankMessage;
        }
      }
    );

    const checkStaff = async (chatBoxs, staffLists) => {
      for (let index = 0; index < chatBoxs.length; index++) {
        const element = chatBoxs[index];
        var staff = staffLists;
        if (staff.length === 0 && element.staff_id !== null) {
          const response = await getStaff(element.staff_id);
          if (response.status === "success") {
            response.data.staff_id = element.staff_id;
            staff.push(response.data);
            store.commit("centersectorModule/SET_STAFFLISTS_DATA", staff);
          }
          chatBoxs[index].staff_name = staff[0].name;
        } else if (staff.length > 0 && element.staff_id !== null) {
          let indexStaff = staff.findIndex((item) => {
            return item.staff_id === element.staff_id;
          });

          if (indexStaff === -1) {
            const response = await getStaff(element.staff_id);
            if (response.status === "success") {
              response.data.staff_id = element.staff_id;
              staff.push(response.data);
              store.commit("centersectorModule/SET_STAFFLISTS_DATA", staff);
              chatBoxs[index].staff_name = response.data.name;
            }
          } else {
            chatBoxs[index].staff_name = staff[indexStaff].name;
          }
        }
      }
    };

    const handleClickOutSide = (event) => {
      if (
        emojiPicker.value &&
        !emojiPicker.value.contains(event.target) &&
        emojiIcon.value &&
        !emojiIcon.value.contains(event.target)
      ) {
        state.showEmojiPicker = false;
      }
      if (
        stickerPicker.value &&
        !stickerPicker.value.contains(event.target) &&
        stickerIcon.value &&
        !stickerIcon.value.contains(event.target)
      ) {
        state.showStickerPicker = false;
      }
    };

    const chatScrollBottom = () => {
      if (state.chatLength > 20) {
        return;
      }
      clearInterval(window.scrollBottom);
      window.scrollBottom = setInterval(() => {
        scrollarea.value.setScrollPercentage("vertical", 1);

        if (scrollarea.value.getScrollPercentage().top === 1) {
          clearInterval(window.scrollBottom);
        }
      }, 100);
    };

    const handlePaste = (event) => {
      var items = (event.clipboardData || window.clipboardData).items;
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          var file = items[i].getAsFile();
          state.dropzone.addFile(file);
        }
      }
    };

    const allowDrop = (event) => {
      event.preventDefault();
    };

    const onDrop = (event) => {
      event.preventDefault();
      if (event.dataTransfer.getData("text/plain") !== "") {
        const data = event.dataTransfer.getData("text/plain");
        const draggedElement = document.getElementById(data);
        if (draggedElement !== null && draggedElement.alt === "dragable") {
          fetch(draggedElement.getAttribute("data-original"))
            .then((response) => response.blob())
            .then((blob) => {
              // Create a file object from the blob
              const file = new File([blob], "image.jpg", {
                type: "image/jpeg",
              });
              console.log(file);
              state.dropzone.addFile(file);
              // Use the file object as needed
            })
            .catch((error) => console.error(error));
        }
      } else {
        const data = event.dataTransfer.files;

        if (isImage(data[0])) {
          state.dropzone.addFile(data[0]);
        }
      }
    };

    const isImage = (file) => {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        return false;
      }
      return true;
    };

    const initDropzone = (room_id) => {
      if (state.dropzone) {
        state.dropzone.destroy();
      }
      clearInterval(window.dropzoneIntv);
      window.dropzoneIntv = setInterval(() => {
        const element = document.querySelector("#uploadImgBtn");
        if (element) {
          clearInterval(window.dropzoneIntv);
          state.dropzone = new Dropzone(element, {
            url: `${BASE_API_URL}/api/chatbot/sendimage?room_id=${room_id}
            `,
            acceptedFiles: "image/*",
            addRemoveLinks: true,
            paramName: "image",
            autoProcessQueue: false,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });

          state.dropzone.on("addedfile", function (file) {
            console.log(file);
          });

          state.dropzone.on("success", function (file, response) {
            // Handle the success response here (if needed)
            console.log(file, response);
            state.dropzone.removeFile(file);
          });
        }
      }, 100);
    };

    const handleClickRemoveFile = () => {
      state.dropzone.removeAllFiles();
    };

    const handleChooseFile = () => {
      fileInput.value.pickFiles();
    };

    const handleUpdateFile = (val) => {
      const selectedFile = val;
      if (state.dropzone.files.length > 0) {
        handleClickRemoveFile();
      }
      if (selectedFile) {
        state.dropzone.addFile(selectedFile);
      } else {
        console.log("No file selected");
      }
    };

    const onScroll = async (loading) => {
      console.log("CHAT LOADING", loading);
      if (
        scrollarea.value.getScrollPosition().top <= 0 &&
        state.chatId !== null &&
        state.isLastMessage === false &&
        state.chatLength >= 20 &&
        loading === false
      ) {
        await handleScroll();
        state.isLoadScroll = false;
      }
    };

    const handleScroll = async () => {
      if (state.isLoadScroll === true) {
        return;
      }
      state.isLoadScroll = true;
      const currentPos = scrollarea.value.getScroll().verticalSize;
      scrollarea.value.setScrollPosition("vertical", 5);
      await store.dispatch("centersectorModule/getOldestChat", {
        id: state.customer.id,
        chat_id: state.chatId,
        web_id: state.customer.web_id,
      });
      setTimeout(() => {
        scrollarea.value.setScrollPosition(
          "vertical",
          scrollarea.value.getScroll().verticalSize - currentPos + 20
        );
        state.isLoadScroll = false;
      }, 100);
    };

    const handleShowImage = (index) => {
      console.log(state.viewer[index]);
      state.viewer[index].show();
    };

    const chatTimeFormat = (time) => {
      const timestamp = time; // Unix timestamp in seconds
      const date = new Date(timestamp * 1000); // convert to milliseconds
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}`; // add leading zero if needed
      return formattedTime;
    };

    const setChatHeight = () => {
      const height = `calc(100% - ${
        chatheader.value !== null ? chatheader.value.clientHeight : 0
      }px - ${
        chatinput.value !== null ? chatinput.value.clientHeight : 0
      }px - 32px )`;
      return height;
    };

    const handleClickEndChat = async (id) => {
      const data = `${id}?work=0`;
      const response = await setRoomWork(data);
      console.log("close work", response);
    };

    const handleSendChat = async (event, id) => {
      if (event.shiftKey) {
        return;
      }
      // const data = `?room_id=${id}&text=${state.messageText}`;
      if (state.dropzone !== null && state.dropzone.files.length > 0) {
        state.dropzone.processQueue();
      }
      if (state.messageText.trim() === "") {
        return;
      }
      const data = {
        room_id: id,
        text: state.messageText,
      };

      const response = await sendText(data);
      console.log("SEND TEXT", response);
      state.messageText = "";
    };

    const handleClickIsPin = (id, event) => {
      store.dispatch("centersectorModule/setIsBtn", true);
      updateRooms(id, event);
    };

    const handleClickUnread = (id) => {
      store.dispatch("centersectorModule/setIsBtn", true);
      updateRooms(id, "is_read=0");
    };

    const handleClickIsProcess = (id, event) => {
      store.dispatch("centersectorModule/setIsBtn", true);
      updateRooms(id, event);
    };

    const handleClickForceEnd = async (id) => {
      const data = `${id}?work=1&force_close=1`;
      const response = await setRoomWork(data);
      store.dispatch("centersectorModule/setPreview", false);
      console.log("Force Close", response);
    };

    const handleClickStartConversation = async (id) => {
      const data = `${id}?work=1`;
      const response = await setRoomWork(data);
      if (response.status === "error") {
        state.confirmForceEnd = true;
      } else {
        store.dispatch("centersectorModule/setPreview", false);
      }

      // getChat(id, web_id);
    };

    const onDragStart = (event) => {
      event.dataTransfer.setData("text/plain", event.target.id);
    };

    const onSelectEmoji = (emoji) => {
      state.messageText += emoji.native;
    };

    const onSelectSticker = async (room_id, package_id, sticker_id) => {
      const data = {
        room_id: room_id,
        package_id: package_id,
        sticker_id: sticker_id,
      };
      const response = await sendSticker(data);
      console.log(response);
    };

    const onSelectStickerPack = (package_id) => {
      state.currentStickerPackage = package_id;
    };

    const handleClickResend = async (room_id, message_id) => {
      const data = { room_id: room_id, line_message_id: message_id };
      const response = await resend(data);
      console.log("resend", response);
    };

    return {
      chatheader,
      chatinput,
      fileInput,
      scrollarea,
      emojiIndex,
      emojiPicker,
      emojiIcon,
      stickerPicker,
      stickerIcon,
      ...toRefs(state),
      chatScrollBottom,
      onScroll,
      handlePaste,
      onDrop,
      allowDrop,
      handleClickRemoveFile,
      handleChooseFile,
      handleUpdateFile,
      handleShowImage,
      chatTimeFormat,
      setChatHeight,
      handleClickEndChat,
      updateRooms,
      isEmpty,
      handleSendChat,
      searchObjectById,
      handleClickIsPin,
      handleClickUnread,
      handleClickIsProcess,
      handleClickForceEnd,
      handleClickStartConversation,
      onDragStart,
      onSelectEmoji,
      onSelectSticker,
      onSelectStickerPack,
      handleClickResend,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-dialog {
  background-color: $bg-dialog;
}
.bg-grey-66 {
  background-color: $grey-66;
}
.bg-dark-blue {
  background-color: $dark-blue;
}
.bg-black-blue {
  background-color: $black-blue;
}
.bg-dark-orange {
  background-color: $dark-orange;
}
.bg-black-orange {
  background-color: $black-orange;
}
.bg-dark-green {
  background-color: $dark-green;
}
.bg-light-green {
  background-color: $light-green;
}
.bg-yellow {
  background-color: $yellow;
}
.text-normal-green {
  color: $normal-green;
}

.text-time {
  color: $grey-bc;
}
.chat-sector {
  background-color: white;
  border-radius: 1rem;
  height: calc(100%);

  & .not-chat {
    font-size: 1.375rem;
    color: $light-grey;
    background-color: $background;
  }
  & .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: white;
    background: $gd-green;
    border-radius: 1rem;
  }
  & .chat-body {
    & .fail-message {
      text-align: right;
      padding-right: 2rem;
      color: $light-red;
    }
    & .chat-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & .date-item {
        color: white;
        background-color: #c1c1c1;
        border-radius: 0.75rem;
      }
      &.center {
        justify-content: center;
      }
      &.mine {
        justify-content: flex-end;
      }
      & .message {
        max-width: 60%;
        padding: 0.5rem;
        background-color: $background;
        border-radius: 0.75rem;
        white-space: pre-line;
        &.alt {
          background-color: $blue-mint;
        }
      }
      & .img {
        cursor: zoom-in;
        border-radius: 0.75rem;
      }
      & .avatar {
        position: relative;
        & .admin-info {
          z-index: 3;
          position: absolute;
          display: none;
          bottom: 0;
          right: 00%;
          transform: translate(0%, 110%);
          padding: 0.25rem 0.5rem;
          color: $grey;
          background: $white-grey;
          border-radius: 0.5rem;
        }
        &:hover {
          & .admin-info {
            display: block;
          }
        }
      }
    }
  }
}
.sticker-sector {
  position: absolute;
  bottom: 2rem;
  right: 0;
  transform: translate(-30%, 0);
  background: white;
  border: #4e4e4e 1px solid;
  border-radius: 1rem;
  width: 300%;
  padding: 0.5rem;
  & .sticker-header {
    display: flex;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    & div {
      width: 60px;
      height: 60px;
      cursor: pointer;
      & img {
        height: 100%;
        &.activate {
          background-color: grey;
          border-radius: 1rem;
        }
      }
    }
  }
  & .sticker-body {
    max-height: 37vh;
    overflow-y: auto;
    scrollbar-width: thin;
    & div {
      width: 20%;
      cursor: pointer;
      & img {
        width: 100%;
        height: 80%;
      }
    }
  }
}
.emoji-sector {
  position: absolute;
  bottom: 2rem;
  right: 0;
  transform: translate(-30%, 0);
}
.loading-text {
  text-align: center;
  margin: 0 -1rem;
  padding: 0.5rem 0;
  background: rgba(0, 0, 0, 0.1);
  color: #4e4e4e;
  span {
    animation: dot 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
</style>
