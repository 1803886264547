<template>
  <div
    v-if="isEmpty(customer) === false"
    class="col-3 q-gutter-y-md"
    style="width: 20% !important"
  >
    <div class="right-sector h-top">
      <div ref="profileHeader" class="flex justify-center font-bolds">
        <div class="profile-header">
          {{ customer.web ? customer.web.name : "" }}
        </div>
      </div>
      <div
        class="q-gutter-y-md q-mt-none q-pb-sm font-semibold"
        :style="{ height: setProfileHeight() }"
      >
        <div ref="profileBody1" class="text-center">
          <q-avatar class="avatar-right relative-position">
            <img :src="customer.picture_url" />
            <q-icon
              @click="handleClickRefreshCustomer(customer.id)"
              class="refresh-icon q-pa-xs cursor-pointer"
              color="grey-95"
              name="refresh"
              size="xs"
            ></q-icon>
          </q-avatar>
        </div>
        <div ref="profileBody2" class="text-center">
          {{ customer.display_name }}
          <q-icon
            @click="
              handleClickChangeCustomer(
                true,
                customer.id,
                customer.display_name,
                customer.note
              )
            "
            class="q-pa-xs cursor-pointer"
            color="grey-95"
            name="border_color"
            style="transform: translateY(-5%)"
          ></q-icon>
        </div>
        <div ref="profileBody3">
          <div class="flex justify-center q-mb-md">
            <q-btn
              @click="
                (detailMode = 'financial'),
                  (detailTab = 'left'),
                  handleClickLeftTab(customer.id)
              "
              unelevated
              :color="detailMode === 'financial' ? 'gd-green' : 'white-grey'"
              :text-color="detailMode === 'financial' ? 'white' : 'grey'"
              label="รายงานการเงิน"
              class="btn-radius"
            />
            &emsp;
            <q-btn
              @click="
                (detailMode = 'account'), handleClickAccountData(customer.id)
              "
              unelevated
              :color="detailMode === 'account' ? 'gd-green' : 'white-grey'"
              :text-color="detailMode === 'account' ? 'white' : 'grey'"
              label="ข้อมูลบัญชี"
              class="btn-radius"
            />
          </div>
          <div class="customer-note q-mx-md">
            <div
              @click="
                handleClickChangeCustomer(
                  false,
                  customer.id,
                  customer.display_name,
                  customer.note
                )
              "
              class="row q-pa-sm font-regular cursor-pointer"
            >
              <div class="col-10 ellipsis">
                <q-icon
                  color="grey-95"
                  name="description"
                  style="transform: translateY(-5%)"
                ></q-icon>
                {{
                  customer.note !== null &&
                  customer.note !== "" &&
                  customer.note !== "-"
                    ? customer.note
                    : "บันทึกช่วยจำ"
                }}
              </div>
              <div class="col-2 text-right">แก้ไข</div>
            </div>
          </div>
        </div>
        <div
          class="detail-account-sector q-mx-sm"
          :style="{ height: setProfileBodyHeight() }"
        >
          <q-tabs
            v-if="detailMode === 'financial'"
            v-model="detailTab"
            dense
            class="tabHeader text-grey-66 round-top"
            active-color="black"
            indicator-color="transparent"
            align="justify"
          >
            <q-tab
              @click="handleClickLeftTab(customer.id)"
              class="round-top font-regular"
              name="left"
              label="ฝาก"
            />
            <q-tab
              @click="handleClickRightTab(customer.id)"
              class="round-top font-regular"
              name="right"
              label="ถอน"
            />
          </q-tabs>
          <q-tab-panels
            v-if="detailMode === 'financial'"
            v-model="detailTab"
            animated
            class="bg-background round-bottom"
          >
            <q-tab-panel
              name="left"
              class="q-pa-sm"
              :style="{ height: setTabsHeight() }"
            >
              <q-scroll-area class="full-height">
                <div
                  v-show="depositLists.length > 0"
                  v-for="(depositList, index) in depositLists"
                  :key="index"
                  @click="handleClickDepositDetail(depositList.id)"
                  class="detail-financial-item row q-col-gutter-x-sm q-ml-none q-mb-sm cursor-pointer"
                >
                  <div class="col-7 q-pl-none font-medium">
                    <div>
                      <span class="text-grey-66">ID : </span>
                      <span>{{ depositList.code }}</span>
                    </div>
                    <div>
                      <span class="text-grey-66">จำนวน : </span>
                      <span>{{ numberWithCommas(depositList.amount) }}฿</span>
                    </div>
                  </div>
                  <div class="col-5 font-semibold self-center">
                    <q-badge
                      :color="
                        depositList.status >= 900
                          ? 'normal-green'
                          : depositList.status >= 300
                          ? 'light-red'
                          : depositList.status >= 100
                          ? 'dark-orange'
                          : 'light-red'
                      "
                      class="q-pa-sm full-width justify-center"
                    >
                      {{
                        depositList.status >= 900
                          ? "เรียบร้อย"
                          : depositList.status >= 300
                          ? "ยกเลิก"
                          : depositList.status >= 100
                          ? "รอดำเนินการ"
                          : "Unknown"
                      }}
                    </q-badge>
                  </div>
                </div>
                <div
                  v-show="depositLists.length <= 0"
                  class="font-regular text-center text-grey-95 absolute-center full-width"
                >
                  ไม่มีข้อมูลการฝากในระบบ
                </div>
              </q-scroll-area>
            </q-tab-panel>

            <q-tab-panel
              name="right"
              class="q-pa-sm"
              :style="{ height: setTabsHeight() }"
            >
              <q-scroll-area class="full-height">
                <div
                  v-show="withdrawLists.length > 0"
                  v-for="(withdrawList, index) in withdrawLists"
                  :key="index"
                  @click="handleClickWithdrawDetail(withdrawList.id)"
                  class="detail-financial-item red row q-col-gutter-x-sm q-ml-none q-mb-sm cursor-pointer"
                >
                  <div class="col-7 q-pl-none font-medium">
                    <div>
                      <span class="text-grey-66">ID : </span>
                      <span>{{ withdrawList.code }}</span>
                    </div>
                    <div>
                      <span class="text-grey-66">จำนวน : </span>
                      <span>{{ numberWithCommas(withdrawList.amount) }}฿</span>
                    </div>
                  </div>
                  <div class="col-5 font-semibold self-center">
                    <q-badge
                      :color="
                        withdrawList.status >= 900
                          ? 'normal-green'
                          : withdrawList.status >= 300
                          ? 'light-red'
                          : withdrawList.status >= 100
                          ? 'dark-orange'
                          : 'light-red'
                      "
                      class="q-pa-sm full-width justify-center"
                    >
                      {{
                        withdrawList.status >= 900
                          ? "เรียบร้อย"
                          : withdrawList.status >= 300
                          ? "ยกเลิก"
                          : withdrawList.status >= 100
                          ? "รอดำเนินการ"
                          : "Unknown"
                      }}
                    </q-badge>
                  </div>
                </div>
                <div
                  v-show="withdrawLists.length <= 0"
                  class="font-regular text-center text-grey-95 absolute-center full-width"
                >
                  ไม่มีข้อมูลการถอนในระบบ
                </div>
              </q-scroll-area>
            </q-tab-panel>
          </q-tab-panels>
          <!-- แก้ -->
          <div v-if="detailMode === 'account'" class="full-height q-pa-sm">
            <q-scroll-area v-if="isUserDetail === false" class="full-height">
              <div
                v-show="userLists.length > 0"
                v-for="(userList, index) in userLists"
                :key="index"
                class="detail-account-item cursor-pointer row q-col-gutter-x-sm q-ml-none q-mb-sm q-pa-md"
                @click="handleClickUserDetail(userList.id, index)"
              >
                <div class="col-1 flex justify-center items-center q-pl-none">
                  <div class="">
                    <q-icon name="person" color="black" size="sm"></q-icon>
                  </div>
                </div>
                <div class="col-10">{{ userList.user }}</div>
                <div class="col-1 q-pl-xs">
                  <q-icon name="chevron_right" color="black" size="sm"></q-icon>
                </div>
              </div>
              <div
                v-show="userLists.length <= 0"
                class="font-regular text-center text-grey-95 absolute-center full-width"
              >
                ไม่มีข้อมูล User ในระบบ
              </div>
            </q-scroll-area>
            <q-scroll-area v-if="isUserDetail === true" class="full-height">
              <div
                @click="isUserDetail = false"
                class="detail-account-item grey row q-ml-none q-mb-md q-pa-sm"
              >
                <div class="col-1 cursor-pointer">
                  <q-icon name="chevron_left" size="sm"></q-icon>
                </div>
                <div class="col-11 text-center">
                  {{ userLists[userDetailId].user }}
                </div>
              </div>
              <div class="q-ml-md q-mb-md" style="font-size: 1.075rem">
                บัญชีฝาก
              </div>
              <div
                v-show="userDetailLists.length > 0"
                v-for="(userDetailList, index) in userDetailLists"
                :key="index"
                class="detail-account-item row q-col-gutter-x-sm q-ml-none q-mb-sm q-pa-md"
              >
                <div class="col-2 flex justify-center items-center q-pl-none">
                  <div
                    class="bank-icon full-height full-width"
                    :class="userDetailList.bankCode"
                  ></div>
                </div>
                <div class="col-9">
                  <div>
                    {{
                      userDetailList.bankName + " " + userDetailList.accNumber
                    }}
                  </div>
                  <div class="font-regular">{{ userDetailList.accName }}</div>
                </div>
                <div
                  @click="
                    handleClickSendToMessage(
                      userDetailList.bankName,
                      userDetailList.accNumber,
                      userDetailList.accName
                    )
                  "
                  class="col-1 flex justify-center items-center cursor-pointer q-pl-xs"
                >
                  <q-icon name="send" color="black" size="xs"></q-icon>
                </div>
              </div>
              <div
                v-show="userDetailLists.length <= 0"
                class="font-regular text-center text-grey-95 absolute-center full-width"
              >
                ไม่มีข้อมูลบัญชีฝากของ User นี้
              </div>
            </q-scroll-area>
          </div>
        </div>
      </div>
    </div>
    <div class="right-sector h-bottom">
      <div class="q-py-sm font-medium">
        <div class="text-center">
          <q-btn
            @click="handleOpanDepositDialog(customer.id, false)"
            unelevated
            color="gd-green"
            text-color="white"
            label="ฝากเงินด้วยสลิป"
            class="btn-radius w-90"
          />
        </div>
        <div class="text-center q-mt-sm">
          <q-btn
            @click="handleOpanDepositDialog(customer.id, true)"
            unelevated
            color="gd-green"
            text-color="white"
            label="ฝากเงิน"
            class="btn-radius w-90"
          />
        </div>
        <div class="text-center q-mt-sm">
          <q-btn
            @click="handleOpanWithdrawDialog(customer.id)"
            unelevated
            color="gd-red"
            text-color="white"
            label="ถอนเงิน"
            class="btn-radius w-90"
          />
        </div>
      </div>
    </div>

    <DepositDialog
      :isDeposits="isDeposit"
      :customer="customer"
      :isNormalDeposit="isNormalDeposit"
      @handleCloseDialog="handleCloseDepositDialog"
    />
    <WithdrawDialog
      :isWithdraws="isWithdraw"
      @handleCloseDialog="handleCloseWithdrawDialog"
    />
    <DepositDetailDialog
      :isDepositDetails="isDepositDetail"
      :depositDetailData="depositDetailData"
      @handleCloseDialog="handleCloseDepositDetailDialog"
    />
    <WithdrawDetailDialog
      :isWithdrawDetails="isWithdrawDetail"
      :withdrawDetailData="withdrawDetailData"
      @handleCloseDialog="handleCloseWithdrawDetailDialog"
    />
    <CustomerDialog
      :isCustomers="isCustomer"
      :customers="customerData"
      @handleCloseDialog="handleCloseCustomerDialog"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from "vue";
import { utilily } from "@/utilities/utility";
import DepositDialog from "./dialog/DepositDialog.vue";
import WithdrawDialog from "./dialog/WithdrawDialog.vue";
import DepositDetailDialog from "./dialog/DepositDetailDialog.vue";
import WithdrawDetailDialog from "./dialog/WithdrawDetailDialog.vue";
import CustomerDialog from "./dialog/CustomerDialog.vue";
import {
  getBankLists,
  getTransactions,
  refreshCustomer,
} from "@/services/rightsector-service";
import { useStore } from "vuex";

export default {
  props: [
    "customer",
    "depositLists",
    "withdrawLists",
    "userLists",
    "isNormalDeposit",
  ],
  components: {
    DepositDialog,
    WithdrawDialog,
    DepositDetailDialog,
    WithdrawDetailDialog,
    CustomerDialog,
  },
  setup(props) {
    const { isEmpty, numberWithCommas } = utilily();

    const profileHeader = ref(null);
    const profileBody1 = ref(null);
    const profileBody2 = ref(null);
    const profileBody3 = ref(null);

    const store = useStore();

    const state = reactive({
      detailTab: "left",
      detailMode: "account",
      isDeposit: false,
      isWithdraw: false,
      isDepositDetail: false,
      isWithdrawDetail: false,
      isCustomer: false,
      depositDetailData: {},
      withdrawDetailData: {},
      customer_id: null,
      isUserDetail: false,
      userDetailId: null,
      userDetailLists: [],
      customerData: {},
    });

    watch(
      () => props.customer,
      (customer) => {
        if (customer.id !== state.customer_id) {
          state.isDeposit = false;
          state.isWithdraw = false;
          state.isUserDetail = false;
          state.isCustomer = false;
          state.detailMode = "account";
        }
        state.customer_id = customer.id;
      }
    );

    const setProfileHeight = () => {
      const height = `calc(100% - ${
        profileHeader.value !== null ? profileHeader.value.clientHeight : 0
      }px )`;
      return height;
    };

    const setProfileBodyHeight = () => {
      const height = `calc(100% - ${
        profileBody1.value !== null ? profileBody1.value.clientHeight : 0
      }px - ${
        profileBody2.value !== null ? profileBody2.value.clientHeight : 0
      }px - ${
        profileBody3.value !== null ? profileBody3.value.clientHeight : 0
      }px - 66px)`;
      return height;
    };

    const setTabsHeight = () => {
      clearInterval(window.tabHeaderIntv);
      window.tabHeaderIntv = setInterval(() => {
        const tabHeader = document.querySelector(".tabHeader");
        if (tabHeader !== null) {
          clearInterval(window.tabHeaderIntv);
          const height = `calc(100% - ${
            tabHeader !== null ? tabHeader.clientHeight : 0
          }px )`;
          const tabPanel = document.querySelector(".q-tab-panels");
          tabPanel.style.height = height;
          return height;
        }
      }, 100);
    };

    const handleOpanDepositDialog = async (id, isNormalMode) => {
      store.commit("rightsectorModule/SET_ISNORMALDEPOSIT_DATA", isNormalMode);
      await store.dispatch("rightsectorModule/getUserLists", id);
      state.isDeposit = true;
    };

    const handleOpanWithdrawDialog = async (id) => {
      await store.dispatch("rightsectorModule/getUserLists", id);
      state.isWithdraw = true;
    };

    const handleCloseDepositDialog = () => {
      state.isDeposit = false;
    };

    const handleCloseWithdrawDialog = () => {
      state.isWithdraw = false;
    };

    const handleClickDepositDetail = async (id) => {
      const req = `/${id}`;
      const response = await getTransactions(req);
      console.log(response.data);
      if (response.status === "success") {
        state.isDepositDetail = true;
        state.depositDetailData = response.data;
      }
    };

    const handleClickWithdrawDetail = async (id) => {
      const req = `/${id}`;
      const response = await getTransactions(req);
      console.log(response.data);
      if (response.status === "success") {
        state.isWithdrawDetail = true;
        state.withdrawDetailData = response.data;
      }
    };

    const handleCloseDepositDetailDialog = () => {
      state.isDepositDetail = false;
    };

    const handleCloseWithdrawDetailDialog = () => {
      state.isWithdrawDetail = false;
    };

    const handleClickLeftTab = async (id) => {
      await store.dispatch("rightsectorModule/getDepositLists", id);
    };

    const handleClickAccountData = async (id) => {
      await store.dispatch("rightsectorModule/getUserLists", id);
    };

    const handleClickRightTab = async (id) => {
      await store.dispatch("rightsectorModule/getWithdrawLists", id);
    };

    const handleClickUserDetail = async (id, index) => {
      state.userDetailId = index;
      const response = await getBankLists(id);
      console.log(response);
      state.isUserDetail = true;
      if (response.status === "success") {
        state.userDetailLists = response.data.toBank;
      } else {
        state.userDetailLists = [];
      }
    };

    const handleClickSendToMessage = (bankName, accNumber, accName) => {
      store.commit(
        "rightsectorModule/SET_BANKMESSAGE_DATA",
        bankName + " " + accNumber + " " + accName
      );
    };

    const handleClickRefreshCustomer = async (id) => {
      const response = await refreshCustomer(id);
      console.log(response);
    };

    const handleClickChangeCustomer = (
      isChangeName,
      id,
      currentName,
      currentNote
    ) => {
      state.isCustomer = true;
      state.customerData.isChangeName = isChangeName;
      state.customerData.id = id;
      state.customerData.currentName = currentName;
      state.customerData.note = currentNote;
    };

    const handleCloseCustomerDialog = () => {
      state.isCustomer = false;
    };

    return {
      profileHeader,
      profileBody1,
      profileBody2,
      profileBody3,
      ...toRefs(state),
      isEmpty,
      numberWithCommas,
      setProfileHeight,
      setProfileBodyHeight,
      setTabsHeight,
      handleOpanDepositDialog,
      handleOpanWithdrawDialog,
      handleCloseDepositDialog,
      handleCloseWithdrawDialog,
      handleClickDepositDetail,
      handleClickWithdrawDetail,
      handleCloseDepositDetailDialog,
      handleCloseWithdrawDetailDialog,
      handleClickAccountData,
      handleClickLeftTab,
      handleClickRightTab,
      handleClickUserDetail,
      handleClickSendToMessage,
      handleClickRefreshCustomer,
      handleClickChangeCustomer,
      handleCloseCustomerDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-background {
  background-color: $background;
}
.text-grey-66 {
  color: $grey-66;
}

.w-90 {
  width: 90%;
}
.round-radius {
  border-radius: 50%;
}
.bg-gd-red {
  background: $gd-red;
}
.avatar-right {
  font-size: 7rem;
  & .refresh-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-15%, -15%);
    background-color: #e4e4e4;
    border-radius: 50%;
  }
}
.right-sector {
  background-color: white;
  border-radius: 1rem 0 0 1rem;
  &.h-top {
    height: calc(70% - 16px);
  }
  &.h-bottom {
    height: calc(30% - 16px);
  }
  & .profile-header {
    padding: 0.75rem;
    text-align: center;
    width: 60%;
    color: white;
    background: $gd-green;
    border-radius: 0 0 1rem 1rem;
  }
  & .customer-note {
    color: $grey-95;
    background-color: $background;
    border-radius: 0.75rem;
  }
  & .detail-account-sector {
    background-color: $background;
    border-radius: 0.5rem;
    & .detail-financial-item {
      padding: 0.25rem 0.5rem;
      background-color: $white-green2;
      border-radius: 0.5rem;
      &.red {
        background-color: $white-red;
      }
    }
    & .detail-account-item {
      background-color: white;
      border-radius: 0.5rem;
      &.grey {
        background-color: #e4e4e4;
        color: #686868;
      }
    }
  }
}
</style>
