<template>
  <q-layout view="hHh lpR fFf" class="bg">
    <headerComponent page="login" />

    <q-page-container>
      <q-page :style-fn="setHeight">
        <div class="row flex justify-center items-center full-height">
          <q-card class="login-card">
            <q-card-section>
              <div class="text-h6 text-center font-medium">ลงชื่อเข้าใช้</div>
              <q-form class="q-gutter-md q-mt-none font-regular">
                <q-input
                  color="grey"
                  filled
                  v-model="username"
                  label="ชื่อผู้ใช้"
                >
                  <template v-slot:prepend>
                    <q-icon name="person" />
                  </template>
                </q-input>
                <q-input
                  :type="isShowPwd === false ? 'password' : 'text'"
                  color="grey"
                  filled
                  v-model="password"
                  label="รหัสผ่าน"
                >
                  <template v-slot:prepend>
                    <q-icon name="lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="
                        isShowPwd === true ? 'visibility' : 'visibility_off'
                      "
                      class="cursor-pointer"
                      color="grey-8f"
                      @click="isShowPwd = !isShowPwd"
                    />
                  </template>
                </q-input>

                <div class="flex justify-end items-center q-mt-none">
                  <q-checkbox
                    v-model="remember"
                    class="text-small"
                    color="grey"
                    label="จำบัญชีนี้"
                  />
                </div>
                <div>
                  <q-btn
                    @click="onSubmit"
                    color="gd-green"
                    text-color="white"
                    class="full-width font-bolds round-radius"
                    label="เข้าสู่ระบบ"
                    unelevated
                  />
                </div>
              </q-form>
            </q-card-section>
          </q-card>
        </div>
        <q-dialog
          v-model="isDialogLogin"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-card class="font-regular rounded bg-white">
            <q-card-section class="flex items-center justify-center q-px-xl">
              <div class="font-medium">
                <div style="font-size: 1.5rem">
                  ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง
                </div>
                <div style="font-size: 1.1rem">
                  กรุณาลองใหม่อีกครั้งหรือติดต่อแอดมิน
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { toRefs } from "vue";
import headerComponent from "@/components/Header.vue";
import { useLogin } from "./composable/use-login";

export default {
  components: { headerComponent },
  setup() {
    const { state, setHeight, onSubmit } = useLogin();

    return {
      ...toRefs(state),
      setHeight,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-grey-8f {
  color: $grey-8f;
}

.login-card {
  border-radius: 1rem;
  & button {
    border-radius: 0.5rem;
  }
}
</style>
