import { computed, onMounted, reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { Login } from "@/services/leftsector-service";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export function useLogin() {
  const { setHeight } = utilily();

  const router = useRouter();
  const store = useStore();

  const state = reactive({
    username: "",
    password: "",
    isShowPwd: false,
    remember: false,
    isDialogLogin: false,
    staff: computed(() => store.state.rightsectorModule.staff),
  });

  onMounted(() => {
    localStorage.removeItem("token");
  });

  const onSubmit = async () => {
    const data = { username: state.username, password: state.password };
    const response = await Login(data);
    console.log("Login", response);
    if (response.status === "success") {
      localStorage.setItem("token", response.access_token);
      router.replace({ name: "homePage" });
    } else {
      state.isDialogLogin = true;
    }
  };

  return { state, setHeight, onSubmit };
}
